/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*prettier-ignore*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    blockquote: "blockquote",
    h2: "h2",
    abbr: "abbr",
    ol: "ol",
    li: "li",
    a: "a",
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.blockquote, null, "\n", React.createElement(_components.h2, null, React.createElement(_components.abbr, {
    title: "Too long, didn't read"
  }, "TL;DR")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "We won't ask you any private information, unless we truly need it."), "\n", React.createElement(_components.li, null, "We don't share your personal informations with anyone."), "\n", React.createElement(_components.li, null, "Your data are stored in a European datacenter own by an American company."), "\n", React.createElement(_components.li, null, "We use ", React.createElement(_components.a, {
    href: "https://stripe.com/us/privacy"
  }, "Stripe"), " and ", React.createElement(_components.a, {
    href: "https://docs.bugsnag.com/legal/privacy-policy/"
  }, "Bugsnag"), "."), "\n", React.createElement(_components.li, null, "If you have any questions, ask us at ", React.createElement(_components.a, {
    href: "mailto:say.hi@forwarder.cc"
  }, "say.hi@forwarder.cc"), "."), "\n"), "\n"), "\n", React.createElement(_components.p, null, "At Forwarder.cc, one of our main priorities is the privacy of our visitors. This Privacy\nPolicy document contains types of information that is collected and recorded by Forwarder.cc\nand how we use it."), "\n", React.createElement(_components.p, null, "If you have additional questions or require more information about our Privacy Policy, do not\nhesitate to contact us through email at ", React.createElement(_components.a, {
    href: "mailto:say.hi@forwarder.cc"
  }, "say.hi@forwarder.cc"), "."), "\n", React.createElement(_components.h3, null, "1. Log files"), "\n", React.createElement(_components.p, null, "Forwarder.cc follows a standard procedure of using log files. These files log visitors when\nthey visit websites. All hosting companies do this and a part of hosting services' analytics. The\ninformation collected by log files include internet protocol (IP) addresses, browser type, Internet\nService Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of\nclicks. These are not linked to any information that is personally identifiable. The purpose of the\ninformation is for analyzing trends, administering the site, tracking users' movement on the\nwebsite, and gathering demographic information."), "\n", React.createElement(_components.h3, null, "2. Cookies"), "\n", React.createElement(_components.p, null, "Like any other website, Forwarder.cc uses \"cookies\". These cookies are used to store\ninformation including visitors' preferences, and the pages on the website that the visitor accessed\nor visited. The information is used to optimize the users' experience by customizing our web page\ncontent based on visitors' browser type and/or other information. You can learn more on our\ndedicated ", React.createElement(_components.a, {
    href: "/us/policies/cookies"
  }, "Cookies Policy"), "."), "\n", React.createElement(_components.h3, null, "3. Third part privacy policies"), "\n", React.createElement(_components.p, null, "Our Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to\nconsult the respective Privacy Policies of these third-party ad servers for more detailed\ninformation. It may include their practices and instructions about how to opt-out of certain\noptions. You may find a complete list of these Privacy Policies and their links here and here :\nPrivacy Policy Links."), "\n", React.createElement(_components.p, null, "You can choose to disable cookies through your individual browser options. To know more detailed\ninformation about cookie management with specific web browsers, it can be found at the browsers’\nrespective websites. What Are Cookies?"), "\n", React.createElement(_components.h3, null, "4. Use of service by minors"), "\n", React.createElement(_components.p, null, "The service are not directed to individuals under the age of thirteen (13), and we request that they\nnot provide Personal Data through the service."), "\n", React.createElement(_components.h3, null, "5. Online privacy policy only"), "\n", React.createElement(_components.p, null, "This privacy policy applies only to our online activities and is valid for visitors to our website\nwith regards to the information that they shared and/or collect in Forwarder.cc. This policy\nis not applicable to any information collected offline or via channels other than this website."), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
